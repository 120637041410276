import React, { Component } from 'react'
import 'firebase/compat/database'
import Toolbar from '../../components/Toolbar'
import MapContainer from '../../components/MapContainer'
import { getPlaces } from '../../lib/firebaseHelper'
import './home.css'

class Home extends Component {
  constructor(props) {
    super(props)

    const init = async () => {
      const places = await getPlaces()
      console.log('places', places)

      /*console.log(' ======= SITEMAP START =======')
      let sitemap = 'https://bestbloodymary.codeunlimited.se\n'
      sitemap += 'https://bestbloodymary.codeunlimited.se/\n'
      places.forEach(place => {
        sitemap += `https://bestbloodymary.codeunlimited.se/${place.key}\n`
        place.ratings.forEach(rating => {
          sitemap += `https://bestbloodymary.codeunlimited.se/${place.key}/${rating.key}\n`
        })
      })
      console.log(sitemap)
      console.log(' ======= SITEMAP END =======')
*/
      this.setState({
        places,
      })
    }

    init()
  }

  state = {
    places: [],
  }

  render() {
    return (
      <div>
        <Toolbar addPadding={false} styleOverride={{ position: 'auto' }} />
        <MapContainer places={this.state.places} />
      </div>
    )
  }
}

export default Home
