import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getPlace, getUri } from '../../lib/firebaseHelper'
import Toolbar from '../../components/Toolbar'
import RatingBar from '../../components/RatingBar'

const calcAvgRating = place => {
  const { ratings = [] } = place
  if (ratings.length > 0) {
    return (ratings.map(rating => rating.rating).reduce((a, b) => a + b) / ratings.length).toFixed(1)
  }
  return ''
}

const styles = {
  container: {
    height: '100%',
    color: '#FFFFFF',
    fontFamily: "'Satisfy', cursive",
    padding: '0 20px'
  },
  centerContainer: {
    width: 1024,
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 80px'
  },
  ratingsContainer: {
    textAlign: 'center',
    width: '100%',
    maxWidth: 1922,
    height: '100%',
    margin: '0 auto',
    position: 'relative'
  },
  rating: {
    objectFit: 'cover' /* Do not scale the image */,
    objectPosition: 'center' /* Center the image within the element */,
    height: '25vw',
    width: '25%',
    maxWidth: 250,
    maxHeight: 250,
    minWidth: 150,
    minHeight: 150,
    cursor: 'pointer'
  }
}

function Place(props) {
  const navigate = useNavigate()
  const params = useParams()

  const [place, setPlace] = useState({
    address: 'Loading...',
    location: 'Loading...',
    name: 'Loading...',
    ratings: []
  })
  const [ratingImages, setRatingImages] = useState({})

  const init = async () => {
    try {
      const place = await getPlace(params.place)

      setPlace(place)

      const images = {}
      await Promise.all(
        place.ratings.map(async rating => {
          images[rating.key] = await getUri(rating.key)
        })
      )

      setRatingImages(images)

      if (place.ratings.length === 0) {
        navigate("/")
      }
    } catch (e) {
      console.error(e)
      navigate("/")
    }
  }

  useEffect(() => {
    init()
  })


  const onClickRating = key => {
    navigate(`/${params.place}/${key}`)
  }

  const renderPlaces = () => {
    return place.ratings.slice(0, 4).map(rating => {
      return <img style={styles.rating} src={ratingImages[rating.key]} key={rating.key} alt="View rating" onClick={() => onClickRating(rating.key)} />
    })
  }

  const avgRating = calcAvgRating(place)

  return (
    <div>
      <Toolbar />
      <div style={styles.container}>
        <div style={styles.centerContainer}>
          <header>
            <h1>{place.name}</h1>
            {place && place.ratings && <RatingBar rating={avgRating} />}
            {avgRating}
          </header>
          <div style={styles.ratingsContainer}>{renderPlaces()}</div>
          {/* TODO Add minimap with location */}
        </div>
      </div>
    </div>
  )
}

export default Place
