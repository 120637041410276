import './clusterMarker.css'

const MAX_ZOOM = 20

function ClusterMarker({
    cluster,
    supercluster,
    zoom,
    pointCount,
    pointsLength,
    onClickCluster,
    setZoom,
    setCenter,
}) {
    const { id } = cluster
    const [longitude, latitude] = cluster.geometry.coordinates

    const onClick = () => {
        if (zoom === MAX_ZOOM) {
            onClickCluster(cluster)
        } else {
            const expansionZoom = Math.min(
                supercluster.getClusterExpansionZoom(id),
                MAX_ZOOM
            )
            console.log('Click cluster', cluster, expansionZoom)
            setZoom(expansionZoom)
            setCenter([latitude, longitude])
        }
    }

    return (
        <div
            className="cluster-marker"
            style={{
                width: `${10 + (pointCount / pointsLength) * 20}px`,
                height: `${10 + (pointCount / pointsLength) * 20}px`,
            }}
            onClick={onClick}
        >
            {pointCount}
        </div>
    )
}

export default ClusterMarker