import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import AppRouter from './AppRouter'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import * as serviceWorker from './serviceWorker'

const firebaseConfig = {
  apiKey: 'AIzaSyBcib9tsY7jxVIbwFtIF2DLWLQmV7M5KtA',
  authDomain: 'bloodymaryguide.firebaseapp.com',
  databaseURL: 'https://bloodymaryguide.firebaseio.com',
  projectId: 'bloodymaryguide',
  storageBucket: 'bloodymaryguide.appspot.com',
  messagingSenderId: '637926959083',
  appId: '1:637926959083:web:d1899505c543e8e0',
}

firebase.initializeApp(firebaseConfig)

// firebase
//   .auth()
//   .signInAnonymously()
//   .catch(error => {
//     // Handle Errors here.
//     var errorCode = error.code
//     var errorMessage = error.message
//     console.log('Failed to log in', errorCode, errorMessage)
//   })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<AppRouter />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// SET METADATA FOR WEB INDEXING
function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

let title = 'Best Bloody Mary'
const placeName = getQueryVariable('t')
if (!!placeName) {
  title += ` @ ${placeName}`
}

let description = 'Rate awesome Bloody Mary drinks and find the Best Bloody Mary places'
const rating = getQueryVariable('r')
if (!!rating) {
  description = `New rating: ${rating} / 5`
}

const desc = getQueryVariable('d')
if (!!desc) {
  description = `\n${desc}`
}

const img = getQueryVariable('i')

document.title = title
document.querySelector('meta[name="description"]').setAttribute('content', description)
document.querySelector('meta[property="og:title"]').setAttribute('content', title)
document.querySelector('meta[property="og:description"]').setAttribute('content', description)
document.querySelector('meta[property="og:image"]').setAttribute('content', img)
// document.querySelector('meta[name="twitter:card"]').setAttribute("content", "summary_large_image");
document.querySelector('meta[name="twitter:title"]').setAttribute('content', title)
document.querySelector('meta[name="twitter:description"]').setAttribute('content', description)
document.querySelector('meta[name="twitter:image"]').setAttribute('content', img)
