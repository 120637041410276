import React from 'react'

const commonStyle = {
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  fontSize: 'calc(10px + 2vmin)',
  fontFamily: "'Satisfy', cursive",
  height: 70
}

const styles = {
  placeholder: {
    ...commonStyle,
    opacity: 0
  },

  toolbar: {
    ...commonStyle,
    backgroundColor: '#ed1c24',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    cursor: 'pointer'
  },
  title: {
    width: 1024,
    maxWidth: '100%',
    paddingLeft: 50,
    margin: '0 auto',
    textAlign: 'left'
  }
}

function Toolbar({ title = 'Best Bloody Mary', addPadding = true, styleOverride = {} }) {
  function handleClick(e) {
    e.preventDefault()
    console.log('The link was clicked.')
    window.location = '/'
  }

  return (
    <>
      {addPadding && <header style={styles.placeholder}>&nbsp;</header>}
      <header style={{ ...styles.toolbar, ...styleOverride }} onClick={handleClick}>
        <div style={styles.title}>{title}</div>
      </header>
    </>
  )
}

export default Toolbar
