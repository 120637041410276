import './placeMarker.css'

const calcAvgRating = place => {
    const { ratings = [] } = place
    if (ratings.length > 0) {
        return Math.round((ratings.map(rating => rating.rating).reduce((a, b) => a + b) / ratings.length).toFixed(1))
    }
    return 1
}

function PlaceMarker({ cluster, onClick }) {
    const { name } = cluster.properties

    const classNames = "place-marker rating" + calcAvgRating(cluster.place)
    return <div className={classNames} onClick={onClick} title={name} />
}

export default PlaceMarker