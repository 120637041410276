import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { useNavigate, useLocation } from 'react-router-dom'
import Supercluster from 'supercluster'
import theme from './theme.night'
import ClusterMarker from '../ClusterMarker'
import PlaceMarker from '../PlaceMarker'
import CurrentLocation from '../CurrentLocation'

const styles = { container: { position: 'absolute', width: '100%', top: 70, bottom: 0 }, info: { cursor: 'pointer' } }

const Marker = ({ children }) => children

function MapContainer(props) {
  const navigate = useNavigate()
  const location = useLocation()

  const defaultCenter = []
  const defaultZoom = 14

  const [myLocation, setMyLocation] = useState(null)
  const [center, setCenter] = useState(defaultCenter)
  const [zoom, setZoom] = useState(props.zoom || defaultZoom)
  const [bounds, setBounds] = useState(null)

  const [points, setPoints] = useState([])
  const [supercluster, setSupercluster] = useState(null)

  const onMyLocationChanged = (position) => {
    console.log('onMyLocationChanged', position)
    const { latitude, longitude } = position.coords

    setMyLocation(position.coords)
    setCenter([latitude, longitude])
  }

  useEffect(() => {
    console.log("onLoad")
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onMyLocationChanged)
    }
  }, [])

  const createSuperclusterState = (places) => {
    console.log('createSuperclusterState', places)
    const placesWithLocation = places.filter((place) => place.location)
    const points = placesWithLocation.map((place) => ({
      type: 'Feature',
      properties: { cluster: false, id: place.placeId, category: place.name },
      geometry: {
        type: 'Point',
        coordinates: [
          place.location.lng,
          place.location.lat,
        ],
      },
      place,
    }))

    const supercluster = new Supercluster({
      radius: 40,
      maxZoom: 20,
    })

    supercluster.load(points)

    return {
      places,
      points,
      supercluster,
    }
  }

  useEffect(() => {
    console.log("onPlacesChanged", props.places)
    const _state = createSuperclusterState(props.places)
    setPoints(_state.points)
    setSupercluster(_state.supercluster)
  }, [props.places])

  // componentDidUpdate(prevProps) {
  //   const { places, google } = props
  //   const { center } = props.state

  //   // Adjust initial zoom to include 3 closest ratings
  //   if (places.length > 0 && places.length !== prevProps.places.length && !!center) {
  //     const mapLocation = new google.maps.LatLng(center.lat, center.lng)
  //     const closestInDistances = places
  //       .map(place => {
  //         const location = new google.maps.LatLng(place.location.lat, place.location.lng)
  //         return { ...place, distanceFromCenter: google.maps.geometry.spherical.computeDistanceBetween(mapLocation, location) }
  //       })
  //       .sort((a2, b) => a2.distanceFromCenter - b.distanceFromCenter)
  //       .slice(0, 3)

  //     console.log('closestInDistances', closestInDistances)

  //     const bounds = new google.maps.LatLngBounds()
  //     bounds.extend(mapLocation)
  //     closestInDistances.forEach(place => {
  //       bounds.extend(new google.maps.LatLng(place.location.lat, place.location.lng))
  //     })

  //     this.state.map.fitBounds(bounds)
  //   }
  // }

  const onPlaceClicked = (cluster) => {
    console.log('onPlaceClicked', cluster)

    const [longitude, latitude] = cluster.geometry.coordinates
    setCenter([latitude, longitude])
    setZoom(defaultZoom)
    navigate(`/${cluster.place.key}`)
  }

  const onClickMyLocation = (position) => {
    const { latitude, longitude } = position
    console.log("onClickMyLocation", latitude, longitude)
    setCenter([latitude, longitude])
    setZoom(defaultZoom)
  }

  const onClusterClicked = (cluster) => {
    const children = supercluster.getChildren(cluster.id)
    console.log('onClusterClicked', cluster, children)
  }

  const renderMarkers = () => {
    if (!supercluster || !bounds || bounds.length === 0) return null

    try {
      const clusters = supercluster.getClusters(bounds, zoom)
      return clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties

        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              lat={latitude}
              lng={longitude}
            >
              <ClusterMarker
                cluster={cluster}
                supercluster={supercluster}
                zoom={zoom}
                pointCount={pointCount}
                pointsLength={points.length}
                onClickCluster={onClusterClicked}
                setZoom={setZoom}
                setCenter={setCenter}
              />
            </Marker>
          )
        }

        return (
          <Marker
            key={`course-${cluster.properties.id}`}
            lat={latitude}
            lng={longitude}
          >
            <PlaceMarker
              cluster={cluster}
              onClick={() => onPlaceClicked(cluster)}
            />
          </Marker>
        )
      })
    } catch (e) {
      console.error(e, 'Failed to render markers')
      return null
    }
  }

  const renderMyLocation = () => {
    if (!!myLocation) {
      const { latitude, longitude } = myLocation

      return <Marker
        key="current-location"
        lat={latitude}
        lng={longitude}
      >
        <CurrentLocation onClick={() => onClickMyLocation(myLocation)} />
      </Marker>
    } else {
      return null
    }
  }

  return (
    <div style={styles.container}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={center}
        zoom={zoom}
        onChange={({ center, zoom, bounds }) => {
          const currentSearch = location.search
          const newSearch = `?zoom=${zoom}&center=${center.lat},${center.lng}`
          if (newSearch !== currentSearch) {
            navigate(location.pathname + newSearch)
          }

          setZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ])
        }}
        options={{
          mapTypeControl: false,
          styles: theme
        }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {renderMarkers()}
        {renderMyLocation()}
      </GoogleMapReact>
    </div>
  )
}

export default MapContainer