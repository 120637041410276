import React from 'react'

const styles = {
  content: {
    left: 0,
    right: 0,
    marginBottom: 20
  },
  starPadding: {
    width: 10,
    display: 'inline-block'
  },
  star: show => ({
    maxWidth: 48,
    opacity: show ? 1 : 0
  })
}

function RatingBar({ rating }) {
  return (
    <div style={styles.content}>
      <div>
        <img src={"/star.svg"} alt="star" style={styles.star(Math.round(rating) >= 1)} />
        <span style={styles.starPadding}></span>
        <img src={"/star.svg"} alt="star" style={styles.star(Math.round(rating) >= 2)} />
        <span style={styles.starPadding}></span>
        <img src={"/star.svg"} alt="star" style={styles.star(Math.round(rating) >= 3)} />
        <span style={styles.starPadding}></span>
        <img src={"/star.svg"} alt="star" style={styles.star(Math.round(rating) >= 4)} />
        <span style={styles.starPadding}></span>
        <img src={"/star.svg"} alt="star" style={styles.star(Math.round(rating) >= 5)} />
      </div>
    </div>
  )
}

export default RatingBar
