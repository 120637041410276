import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/storage'

const flatten = (obj) => !!obj ? Object.values(obj) : []

const ratingFilter = (rating) => !rating.inappropriate

const placeFilter = (place) => place.ratings.length > 0

const toLocation = ({ lat, lng }) => ({ lat: lat / 1000000, lng: lng / 1000000 })

const getPlaces = async () => {
  const snap = await firebase.database().ref(`/places`).once('value')
  console.log("places", snap.val())

  return flatten(snap.val())
    .map((place) => ({
      ...place,
      location: toLocation(place.location),
      ratings: flatten(place.ratings).filter(ratingFilter)
    }))
    .filter(placeFilter)
}

const getPlace = async (key) => {
  const snap = await firebase.database().ref(`/places/${key}`).once('value')

  const place = snap.val()
  console.log("place", place)

  return { ...place, location: toLocation(place.location), ratings: flatten(place.ratings).filter(ratingFilter) }
}

const getUri = async (key) => {
  try {
    return await firebase.storage().ref(`ratings/${key}/image.jpg`).getDownloadURL()
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export { getPlaces, getPlace, getUri }
