import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Home from './pages/home'
import Place from './pages/place'
import Rating from './pages/rating'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/somewhere/else" />
    }
    return this.props.children
  }
}

function ErrorPage() {
  return <div>ErrorPage</div>
}

function PageNotFound() {
  return <div>PageNotFound</div>
}

function AppRouter() {
  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/:place/:rating" element={<Rating />} />
          <Route path="/:place" element={<Place />} />
          <Route exact path="/" element={<Home />} />
          <Route element={<PageNotFound />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  )
}

export default AppRouter
