import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getPlace, getUri } from '../../lib/firebaseHelper'
import Toolbar from '../../components/Toolbar'
import RatingBar from '../../components/RatingBar'

// https://bloodymaryguide.firebaseio.com/places/-KZYjz26IN3GTB2rCWqX/-KZYjz26IN3GTB2rCWqY
// http://localhost:3000/places/-LgNtvDvG7u_RJ7A9P10
// http://localhost:3000/ratings/-LgNtvDvG7u_RJ7A9P10/-LgNtvDwITXqaORtCRzG

const styles = {
  container: img => ({
    width: '100%',
    height: '100%',
    position: 'fixed',
    color: 'white',
    textAlign: 'center',
    background: `url(${img}) no-repeat center center fixed`,
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover'
  }),

  title: {
    fontSize: 'calc(10px + 2vmin)',
    fontFamily: "'Satisfy', cursive",
    cursor: 'pointer'
  },

  containerOverlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },

  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0
  },

  footerFade: {
    background: 'linear-gradient(rgba(40, 44, 52, 0), rgb(40, 44, 52))',
    height: 150
  },

  footerText: {
    background: '#282c34',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    fontFamily: "'Permanent Marker', cursive",
    color: 'white'
  }
}

function Rating(props) {
  const navigate = useNavigate()
  const params = useParams()


  const [place, setPlace] = useState({
    address: 'Loading...',
    location: 'Loading...',
    name: 'Loading...',
    ratings: []
  })
  const [rating, setRating] = useState({
    description: 'Loading...',
    rating: undefined
  })
  const [imageUrl, setImageUrl] = useState(undefined)

  const init = async () => {
    try {
      console.log('init', props)
      const [_place, _imageUrl] = await Promise.all([getPlace(params.place), getUri(params.rating)])
      const _rating = _place.ratings.find(rating => rating.key === params.rating)
      console.log('place', _place)
      console.log('imageUrl', _imageUrl)

      setPlace(_place)
      setRating(_rating)
      setImageUrl(_imageUrl)

      if (!_rating) {
        navigate(`/${params.place}`)
      }
    } catch (e) {
      navigate(`/${params.place}`)
    }
  }

  useEffect(() => {
    init()
  })

  const onClickPlace = () => {
    console.log('onClickPlace')
    navigate(`/${params.place}`)
  }


  return (
    <div style={styles.container(imageUrl)}>

      <div style={styles.containerOverlay}>
        <Toolbar />
        <header style={styles.title} onClick={onClickPlace}>
          <h1>{place.name}</h1>
        </header>
        {rating && rating.rating && <RatingBar rating={rating.rating} />}
        <div style={styles.footer}>
          <div style={styles.footerFade}></div>
          <div style={styles.footerText}>{rating && rating.description && rating.description}</div>
        </div>
      </div>
    </div>
  )
}

export default Rating
